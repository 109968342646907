<template>
  <layout-vertical>

    <router-view />

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      checkData : null,
    }
  },
  methods: {
    checkToken : function(){
      this.api.checkExpiredToken()
        .then((res) => {
            let data = res.data.data;

            if(res.data.success && !!data.expired){
                this.checkData = setTimeout(() => {
                    this.checkToken();
                }, data.validTime);
            }
            else{ 
                this.showDisconnected();
            }
        }).catch(error=>{
          console.log(error);
        });
    },
    showDisconnected: function(){
        // this.msgBox(this.disconnectMsg(), {
        //     title : this.$t("login.alarm.title"),
        //     okTitle : this.$t("login.alarm.okText")
        // }).then(() => {
        //     location.replace('logout');
        // });
        //location.replace('login');
        
    },
  },mounted() {
    //this.checkToken();

  },beforeDestroy() {
    if(this.checkData != null) clearTimeout(this.checkData);
  },
}
</script>
