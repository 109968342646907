export default [
  {
    title: "관제유형 선택",
    i18nCode: "label.L0015",
    icon: "FolderIcon",
    route: "selectProject",
    pageAuth: ["SA"],
  },
  {
    title: "대시보드",
    i18nCode: "label.L0001",
    icon: "GridIcon",
    // route: "dashboard",
    // target: '_blank',
    // href:window.location.href = "http://127.0.0.1:34051/#/dashboard"
    // href: '/dashboard',
    route: "dashboard",
    pageAuth: ["A", "U"],
  },
  {
    title: "돌발 이력",
    i18nCode: "label.L0002",
    icon: "AlertCircleIcon",
    route: "event",
    pageAuth: ["A", "U"],

  },
  {
    title: "장비 관리",
    i18nCode: "label.L0003",
    icon: "ToolIcon",
    route: "equipment",
    pageAuth: ["A", "U"],
  },

  {
    title: "공사일정 관리",
    i18nCode: "label.L0004",
    icon: "CalendarIcon",
    route: "schedule",
    pageAuth: ["A", "U"],
  },
  // {
  //   header: 'administration',
  //   icon: 'LayersIcon',
  //   children:[

  //   ]
  //   // icon: "CalendarIcon",
  //   // route: "schedule",
  // },
  {
    title: "통계 이력",
    i18nCode: "label.L0006",
    icon: "BarChartIcon",
    route: "statistics",
    pageAuth: ["A", "U"],
  },
  {
    title: "사용자 관리",
    i18nCode: "label.L0005",
    icon: "UserIcon",
    route: "user",
    pageAuth: ["A"],
  },

  // {
  //   title: "타임리스",
  //   icon: "ClockIcon",
  //   route: "replay",
  // },
];
